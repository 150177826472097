
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "banner": images({}),
            "bannerText": {
                "type": "array",
                "items": {
                    "type": "string"
                },
                "ui": {
                    "label": "banner上文字"
                }
            },
            "sloganImage": images({}),

            "slogan": {
                "type": "string",
                "ui": {
                    "label": "slogan"
                }
            },
            "subslogan": {
                "type": "string",
                "ui": {
                    "label": "subslogan"
                }
            },
            "rightImage": images({
                "label": "右侧分类图片"
            }),
            "doForScenic": {
                "type": "string",
                "ui": {
                    "label": "doForScenic"
                }
            },
            "certLeftImage": images({}),
            "certIntroduction": {
                "type": "string",
                "ui": {
                    "label": "certIntroduction"
                }
            },

            "address": {
                "type": "string",
                "ui": {
                    "label": "address"
                }
            },

            "telphone": {
                "type": "string",
                "ui": {
                    "label": "telphone"
                }
            },

            "email": {
                "type": "string",
                "ui": {
                    "label": "email"
                }
            },
            "beian1": {
                "type": "string",
                "ui": {
                    "label": "beian1"
                }
            },
            "beian2": {
                "type": "string",
                "ui": {
                    "label": "beian2"
                }
            },
            "kefu1": {
                "type": "string",
                "ui": {
                    "label": "kefu1"
                }
            },
            "kefu2": {
                "type": "string",
                "ui": {
                    "label": "kefu2"
                }
            },



        }
    }